/* eslint-disable import/no-unresolved */
import { useState } from 'react';

import * as getStatic from '@backpackjs/core-nextjs/get-static';
import { useCustomerAuth, useSettings } from '@backpackjs/storefront';

import templates from '../../templates/account';
import { AccountsAuthLoader } from '../../_components';

export const getStaticProps = getStatic.props.settings;

const LoginTemplate = templates['login'];
const authOptions = {
  autoRedirect: true,
  defaultRoute: '/account/orders',
  id: 'withLogin',
};

export default function Login({ settings: initialSettings }) {
  const [action, setAction] = useState('login');
  const liveSettings = useSettings();
  const settings = liveSettings || initialSettings;

  // will redirect to account if signed in
  const { authenticated } = useCustomerAuth(authOptions);

  return authenticated === false ? (
    LoginTemplate ? (
      <LoginTemplate
        action={action}
        setAction={setAction}
        action={action}
        setAction={setAction}
        settings={settings}
      />
    ) : (
      'Login template not provided'
    )
  ) : (
    <AccountsAuthLoader /> // while you get redirected
  );
}
